<template>
    <div class="my-6">
        <h3 class="font-bold">List of {{ route.name }}</h3>
        <span class="text-sm text-gray-500">From this screen, you could enable or disable the months in which you want your
            users to be able to enter time records. Remembering that this control does not overlap the project date.
        </span>
    </div>
    <div class="card">
        <DataTable :headers="headers" :data="data" :options="options" color="primary" />
    </div>
</template>
  
<script>
import DataTable from "../../components/Elements/Datatable.vue";

import { inject, onMounted, ref } from "vue";
import { GET, PATCH } from "../../services/api";
import { useRoute, useRouter } from "vue-router";

export default {
    name: "controlers",
    components: {
        DataTable
    },
    setup() {
        const headers = [
            { key: "Name", value: "name" },
            { key: "Status", value: "active" },
            { key: "Options", value: "" },
        ];

        const route = useRoute();
        const router = useRouter();
        const modal = inject("modal");
        const alert = inject("alert");
        const loader = inject("loading");
        const data = ref([]);

        onMounted(async () => {
            try {
                loader.open();
                const controls = await GET("controls");

                data.value = controls.map(el => {
                    const schema = {
                        ...el,
                        active: el.status == true ? 'active' : 'desactive'
                    }

                    return schema

                }).sort((a, b) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0))

                loader.close();
            } catch (e) {
                loader.close();
                if (e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
                    localStorage.removeItem("t_dynamik");
                    localStorage.removeItem("info_dynamik");
                    router.push({ path: '/login' });
                }
                alert.open("Error", e, "danger");
            }
        });


        const changeStatus = (user) => {
            try {

                const statusFunction = async function () {
                    try {
                        loader.open();
                        await PATCH(`controls/${user.id}`, { status: !user.status });



                        const controls = await GET("controls");

                        data.value = controls.map(el => {
                            const schema = {
                                ...el,
                                active: el.status == true ? 'active' : 'desactive'
                            }

                            return schema

                        }).sort((a, b) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0))

                        loader.close();
                        alert.open("Success!", `User <b>${user.name}</b> was update successfully!`, "success");
                    } catch (e) {
                        loader.close();
                        if (e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
                            localStorage.removeItem("t_dynamik");
                            localStorage.removeItem("info_dynamik");
                            router.push({ path: '/login' });
                        }
                        alert.open("Error", e, "danger");
                    }
                }

                loader.open();
                modal.open(
                    "Attention",
                    "Do you really wish to change this month status?",
                    "warning",
                    "Yes",
                    () => statusFunction(),
                    modal.close()
                );
                loader.close();
            } catch (error) {
                loader.close();
            }
        }

        // const show = (r) => {
        //   router.push(`/parceiros/editar/${r.id}`);
        // };

        const options = [
        { icon: "block", action: (param) => changeStatus(param), title: "Change status", use:'status' },
        ];

        return { headers, data, options, route };
    },
};
</script>
  
<style></style>